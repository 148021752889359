import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogActionsProps as MuiDialogActionsProps,
  DialogContent as MuiDialogContent,
  DialogContentProps as MuiDialogContentProps,
  DialogContentText as MuiDialogContentText,
  DialogContentTextProps as MuiDialogContentTextProps,
  DialogProps as MuiDialogProps,
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
} from '@mui/material';

export const Dialog = ({ ...rest }: MuiDialogProps) => <MuiDialog {...rest} />;
export const DialogActions = ({ ...rest }: MuiDialogActionsProps) => <MuiDialogActions {...rest} />;
export const DialogContent = ({ ...rest }: MuiDialogContentProps) => <MuiDialogContent {...rest} />;
export const DialogContentText = ({ ...rest }: MuiDialogContentTextProps) => (
  <MuiDialogContentText {...rest} />
);
export const DialogTitle = ({ ...rest }: MuiDialogTitleProps) => <MuiDialogTitle {...rest} />;
