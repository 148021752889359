import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import styles from './Deck.module.scss';

export interface DeckInterface {
  (props: DeckProps): JSX.Element;
  Item: FC<DeckItem>;
}

interface DeckProps {
  children: ReactNode;
  backgroundColor?: boolean;
  noPaddingTop?: boolean;
  noPaddingBottom?: boolean;
  hasBorderTop?: boolean;
  isSimple?: boolean;
}

interface DeckItem {
  children: ReactNode;
  hasOverflow?: boolean;
  sidePanelIsOpen?: boolean;
  zIndex?: 1 | 2 | 3;
}

export const Deck: DeckInterface = ({
  children,
  backgroundColor,
  noPaddingTop,
  noPaddingBottom,
  hasBorderTop,
  isSimple,
  ...props
}) => (
  <div
    className={classNames(styles.wrapper, {
      [styles.bg]: backgroundColor,
      [styles.noPaddingTop]: noPaddingTop,
      [styles.noPaddingBottom]: noPaddingBottom,
      [styles.hasBorderTop]: hasBorderTop,
      [styles.isSimple]: isSimple,
    })}
    {...props}
  >
    <div className={styles.inner}>{children}</div>
  </div>
);

Deck.Item = ({ hasOverflow, sidePanelIsOpen, zIndex, ...props }): JSX.Element => (
  <div
    className={classNames(styles.item, {
      [styles.overflow]: hasOverflow,
      [styles.sidePanelIsOpen]: sidePanelIsOpen,
      // [styles[`Deck__item____${zIndex}` as keyof typeof styles]]: zIndex,
    })}
    {...props}
  />
);
