import Select, { SelectChangeEvent } from '@mui/material/Select';
import { format } from 'date-fns';
import da from 'date-fns/locale/da';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-GB';
import fi from 'date-fns/locale/fi';
import fr from 'date-fns/locale/fr';
import nb from 'date-fns/locale/nb';
import nl from 'date-fns/locale/nl';
import ru from 'date-fns/locale/ru';
import sv from 'date-fns/locale/sv';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetCurrentLanguage } from 'src/apis/userSettingsAPI';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import { MenuItem } from 'src/components/mui-components/Select';
import { FormControl, InputLabel } from 'src/components/mui-components/Utils';
import { DayRangePicker, Flex, TDateRange } from 'src/components/ui-components';
import { getLanguageFromSiteLocale, localeDateFormat } from 'src/utils/date';
import { stringToPascal } from 'src/utils/string';
import { translationAnyText } from 'src/utils/translation';
import { IOptionsChangeParameters } from '../../types/resourcePlanner';

const localeMap = {
  da,
  de,
  en,
  fi,
  nb,
  nl,
  ru,
  sv,
  fr,
};

export interface IResourceViewField {
  name: string;
  value?: string;
  options: Array<{
    value: string;
    label: string;
  }>;
}

interface ResourceViewOptionsProps {
  onChange?: (item: IOptionsChangeParameters[]) => void;
  fields: Array<IResourceViewField>;
  initialDateStart?: Date;
  initialDateEnd?: Date;
}

export const ResourceViewOptions = ({
  fields: fieldConfigs,
  onChange,
  initialDateStart,
  initialDateEnd,
}: ResourceViewOptionsProps) => {
  const { t } = useTranslation(['dayPicker', 'resourcePlanner']);
  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    onChange?.([{ name, value }]);
  };

  // Date picker options
  const { currentLanguage, isSuccess } = useGetCurrentLanguage();
  const siteLocale = useGetLocale();
  const [dateRange, setDateRange] = useState<TDateRange | undefined>({
    from: initialDateStart,
    to: initialDateEnd,
  });

  useEffect(() => {
    setDateRange({
      from: initialDateStart,
      to: initialDateEnd,
    });
  }, [initialDateStart, initialDateEnd]);

  const handleDateSelect = () => {
    if (dateRange?.from && dateRange?.to) {
      onChange?.([
        {
          name: 'period-starts-at',
          value: format(dateRange.from, 'yyyy-MM-dd'),
        },
        { name: 'period-ends-at', value: format(dateRange.to, 'yyyy-MM-dd') },
      ]);
    }
  };

  const siteLanguage = getLanguageFromSiteLocale(siteLocale);

  return (
    <Flex horizontalAlignment="justify" verticalAlignment="bottom">
      <DayRangePicker
        mode="range"
        dateRange={dateRange}
        setDateRange={setDateRange}
        fromDate={new Date()}
        weekStartsOn={1}
        showWeekNumber
        locale={isSuccess && currentLanguage ? localeMap[currentLanguage] : localeMap.en}
        onApplyHandler={handleDateSelect}
        inputFromLabel={t('dayPicker:DateInputFromLabel')}
        inputToLabel={t('dayPicker:DateInputToLabel')}
        buttonApplyText={t('dayPicker:ApplyButtonText')}
        buttonCancelText={t('dayPicker:CancelButtonText')}
        dateFormatInvalidMessage={t('dayPicker:NotValidDateFormatMessage')}
        dateInvalidMessage={t('dayPicker:NotValidDate')}
        startDateIsOutOfRangeMessage={t('dayPicker:StartDateIsBeforeAllowedDateMessage')}
        endDateIsOutOfRangeMessage={t('dayPicker:EndDateIsBeforeAllowedDateMessage')}
        dateFormat={localeDateFormat(siteLanguage || 'da')}
        preselectOptions={[
          {
            label: t('dayPicker:PreselectButtonOneWeek'),
            periodType: 'week',
            periodAmount: 1,
          },
          {
            label: t('dayPicker:PreselectButtonOneMonth'),
            periodType: 'month',
            periodAmount: 1,
          },
          {
            label: t('dayPicker:PreselectButtonThreeMonths'),
            periodType: 'month',
            periodAmount: 3,
          },
          {
            label: t('dayPicker:PreselectButtonOneYear'),
            periodType: 'year',
            periodAmount: 1,
          },
        ]}
        labels={{
          labelPrevious: (labelMonth) =>
            `${t('dayPicker:PreviousMonthButtonText')}${
              labelMonth
                ? `: ${labelMonth.toLocaleString(currentLanguage, {
                    month: 'long',
                  })}`
                : ''
            }`,
          labelNext: (labelMonth) =>
            `${t('dayPicker:NextMonthButtonText')}${
              labelMonth
                ? `: ${labelMonth.toLocaleString(currentLanguage, {
                    month: 'long',
                  })}`
                : ''
            }`,
          labelWeekNumber: (weekNumber) =>
            `${t('dayPicker:PickEntireWeekButtonText')} ${weekNumber}`,
        }}
      />

      <Flex>
        {fieldConfigs.map(({ name: fieldName, value: fieldValue, options }) => (
          <Flex.Item key={fieldName}>
            <FormControl variant="outlined" size="small">
              <InputLabel id={`${fieldName}-label`}>
                {translationAnyText(
                  t,
                  `resourcePlanner:FieldOption${stringToPascal(fieldName)}Label`,
                )}
              </InputLabel>
              <Select
                labelId={`${fieldName}-label`}
                name={fieldName}
                label={translationAnyText(
                  t,
                  `resourcePlanner:FieldOption${stringToPascal(fieldName)}Label`,
                )}
                value={fieldValue}
                onChange={handleSelectChange}
              >
                {options.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Flex.Item>
        ))}
      </Flex>
    </Flex>
  );
};
