import { StyledEngineProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Suspense } from 'react';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { DefaultLink } from 'src/components/global/DefaultLink';
import { LinkProvider } from 'src/components/global/LinkProvider';
import { LocaleProvider } from 'src/components/global/LocaleProvider';
import { Spinner } from 'src/components/ui-components/Spinner';
import {
  pageSettingsRoutes,
  personalSettingsRoutes,
  systemAdministrationRoutes,
} from 'src/constants/paths';
import MainSearchStoreProvider from 'src/stores/MainSearchStore';
import MobileNavigationStoreProvider from 'src/stores/MobileNavigationStore';
import PageStoreProvider from 'src/stores/PageStore';
import { isDev } from 'src/utils/env';
import styles from './App.module.scss';
import { useGetSiteLocale } from './apis/indexAPI';
import { ReactRouter } from './routers';
import MainRouter from './routers/MainRouter';

export const administrativeRoutes = systemAdministrationRoutes
  .concat(personalSettingsRoutes)
  .concat(pageSettingsRoutes);

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: true,
      },
    },
  });

  const useGetLocale = () => {
    const { siteLocale } = useGetSiteLocale();

    return siteLocale || 'en-GB';
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<ReactRouter />}>
        <Route path="/*" element={<MainRouter />} />
      </Route>,
    ),
  );

  return (
    <LocaleProvider value={{ getLocale: useGetLocale }}>
      <LinkProvider value={DefaultLink}>
        <StyledEngineProvider injectFirst>
          <QueryClientProvider client={queryClient}>
            <Suspense
              fallback={
                <div className={styles.Spinner} data-automation-id="GlobalSpinner">
                  <Spinner />
                </div>
              }
            >
              <MobileNavigationStoreProvider>
                <PageStoreProvider>
                  <MainSearchStoreProvider>
                    <RouterProvider router={router} />
                  </MainSearchStoreProvider>
                </PageStoreProvider>
              </MobileNavigationStoreProvider>
            </Suspense>
            {isDev && <ReactQueryDevtools />}
          </QueryClientProvider>
        </StyledEngineProvider>
      </LinkProvider>
    </LocaleProvider>
  );
};

export default App;
