export default (jsonString: string | null) => {
  if (!jsonString) {
    return null;
  }

  try {
    return JSON.parse(jsonString);
  } catch (ex) {
    return null;
  }
};
