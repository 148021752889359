import MoreVertIcon from '@mui/icons-material/MoreVert';
import PushPinIcon from '@mui/icons-material/PushPin';
import {
  Tab as MuiTab,
  TabProps as MuiTabProps,
  Tabs as MuiTabs,
  TabsProps as MuiTabsProps,
} from '@mui/material';
import { tabsClasses } from '@mui/material/Tabs';
import classNames from 'classnames';
import { IconButton } from '../IconButton';
import { Stack } from '../Stack';
import { Typography } from '../Typography';
import styles from './Tabs.module.scss';

export interface ITabProps extends MuiTabProps {
  isDefault?: boolean;
  tabLabelLength?: number;
  threeDots?: Partial<{
    iconButtonAriaControls: string;
    iconButtonAriaLabel: string;
    iconButtonId: string;
    isActive: boolean;
    isOpen: boolean;
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  }>;
}

export const Tabs = ({ ...rest }: MuiTabsProps) => (
  <MuiTabs
    variant="scrollable"
    scrollButtons="auto"
    {...rest}
    className={classNames(styles.Tabs, ...(rest.className || []))}
    sx={{
      [`& .${tabsClasses.scrollButtons}`]: {
        '&.Mui-disabled': { opacity: 0.3 },
      },
      ...rest.sx,
    }}
  />
);

/**
 * This `<Tab />` adds 2 features to MUI's <Tab />:
 * - An `<IconButton />` with a `<MoreVertIcon />` for a more menu
 * - A `<PushPinIcon />` for default/pinned tabs
 */
export const Tab = ({
  icon,
  iconPosition = 'start',
  isDefault,
  label,
  tabLabelLength = 25,
  threeDots,
  ...rest
}: ITabProps) => {
  const {
    iconButtonAriaControls = 'basic-menu',
    iconButtonAriaLabel = 'more',
    iconButtonId = 'basic-button',
    isActive = false,
    isOpen = false,
    handleClick = () => {},
  } = threeDots ?? {};

  const getTabIcon = () => {
    if (isDefault) {
      return icon ?? <PushPinIcon />;
    }

    return icon;
  };

  const getTruncatedLabel = () => {
    if (!label) {
      return '';
    }
    const l = String(label);
    return l.length > tabLabelLength ? `${l.substring(0, tabLabelLength)}...` : l;
  };

  return (
    <MuiTab
      {...rest}
      icon={getTabIcon()}
      iconPosition={iconPosition || isDefault ? 'start' : iconPosition}
      className={classNames(styles.Tab, ...(rest.className || []))}
      label={
        threeDots ? (
          <Stack spacing={1} flexDirection="row" alignItems="center">
            <Typography fontSize={14} fontWeight={500}>
              {getTruncatedLabel()}
            </Typography>
            {isActive ? (
              <IconButton
                aria-label={iconButtonAriaLabel}
                id={iconButtonId}
                aria-controls={iconButtonAriaControls}
                aria-expanded={!!isOpen}
                aria-haspopup="true"
                onClick={handleClick}
                color="primary"
                size="small"
                className={styles.TabIconButton}
              >
                <MoreVertIcon />
              </IconButton>
            ) : null}
          </Stack>
        ) : (
          label
        )
      }
    />
  );
};

export type TabProps = ITabProps;
